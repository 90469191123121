export default {
    BOLD: 'BOLD',
    ITALIC: 'ITALIC',
    UNDERLINE: 'UNDERLINE',
    STRIKETHROUGH: 'STRIKETHROUGH',
    CODE: 'CODE',
    FONT_FAMILY: 'FONT_FAMILY',
    FONT_SIZE: 'FONT_SIZE',
    FONT_COLOR: 'FONT_COLOR',
    FONT_BACKGROUND: 'FONT_BACKGROUND',
};
